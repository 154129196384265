import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Oauth() {
    const [ cookies, setCookie, removeCookie ] = useCookies();
    
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    
    const location = useLocation();

    useEffect(() => {
        const discordLogin = async () => {
            const searchParams = new URLSearchParams(location.search);
            const code = searchParams.get('code');
            
            if (code) {
                const data = {
                    client_id: clientId,
                    client_secret: clientSecret,
                    grant_type: 'authorization_code',
                    code: code,
                    redirect_uri: window.location.origin + '/oauth',
                    scope: 'identify guilds',
                }
    
                const response = await fetch('https://discord.com/api/oauth2/token', {
                    method: 'POST',
                    body: new URLSearchParams(data),
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
    
                const responseData = await response.json();
                const expirationDate = new Date(Date.now() + responseData.expires_in * 1000);

                try {
                    const userInfoResponse = await fetch ('https://discord.com/api/users/@me/guilds', {
                        method: 'GET',    
                        headers: {
                            Authorization: `Bearer ${responseData.access_token}`
                        }
                    });
                    
                    const data = await userInfoResponse.json();
                    console.log(data);

                    const ids = data.map(item => item.id);
                    console.log(ids);

                    if ( ids.includes("1128407071860207717") ) {
                        setCookie('rT', responseData.refresh_token, { path: '/', expires: expirationDate });
                        setCookie('aT', responseData.access_token, { path: '/' });
                        window.location.href = '/'
                    } else {
                        alert("요구 조건을 만족하지 않는 계정입니다.\n로그인이 거부되었습니다.")
                        window.location.href = '/'
                    }
                } catch(err) {
                    alert("오류가 발생하여 메인 페이지로 이동합니다.")
                    window.location.href = '/'
                }
            }
        }
        
        discordLogin();
    }, [clientId, clientSecret, location]);

    return (
        <>
            디스코드 계정으로 로그인합니다...
        </>
    )
}

export default Oauth;
