import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import '../css/Main.css';
import '../css/Req.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { LoginBtn } from '../Side';

const fetchUserCount = async (_userId) => {
    try {
        const response = await fetch(`${window.location.origin}/api/get_user_req_count`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ id: _userId })
        });

        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        try {
            const response = await fetch(`http://127.0.0.1:8000/api/get_user_req_count`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: _userId })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
};

function Loading() {
    return (
        <>
            <p className="subTitle">
                잠시 기다려주세요...
            </p>
        </>
    );
}

function BasicTextFields(props) {
    const [ songTitle, setSongTitle ] = useState('');
    const [ songArtist, setSongArtist ] = useState('');
    const [ songLink, setSongLink ] = useState('');
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ userCount, setUserCount ] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsDisabled(true);

        const msg = `아래와 같이 요청을 제출합니다.

===============
제목: ${songTitle}
가수: ${songArtist}
링크: ${songLink ? "포함" : "포함 안됨"}
===============

※ 이 작업은 취소할 수 없으며, 한 번 요청한 곡은 삭제할 수 없습니다.
※ 악성 요청이 확인될 경우, 요청이 제한될 수 있습니다.
계속할까요?`
        const userConfirmed = window.confirm(msg);
        if (userConfirmed) {
            try {
                const response = await fetch(`${window.location.origin}/api/add_req`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        title: songTitle,
                        artist: songArtist,
                        userId: props.user.id,
                        link: songLink
                    })
                });
        
                if (response.ok) {
                    alert("요청을 성공적으로 제출했어요.");
                    window.location.reload();
                } else {
                    throw new Error('Failed to submit form data');
                }
            } catch (error) {
                console.log('a')
                try {
                    const response = await fetch(`http://127.0.0.1:8000/api/add_req`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            title: songTitle,
                            artist: songArtist,
                            userId: props.user.id,
                            link: songLink
                        })
                    });

                    if (response.ok) {
                        alert("요청을 성공적으로 제출했어요.");
                        window.location.reload();
                    } else {
                        throw new Error('Failed to submit form data');
                    }
                } catch (error) {
                    console.error('Error submitting form:', error);
                }                
            }
        } else {
            setIsDisabled(false);
        }
    };

    useEffect(() => {
        async function fetchCount() {
            const count = await fetchUserCount(String(props.user.id));
            setUserCount(count);
        }

        fetchCount();
    }, [props.id]);

    if (userCount === null) {
        return (
            <p className="subTitle">
                거의 다 왔어요...
            </p>
        ); // 데이터를 가져오는 동안 로딩 메시지 표시
    } else if (userCount === 5) {
        return (
            <>
                <p className="title">요청 제한에 도달했어요!</p>
                <br />
                <p className="subTitle">
                    최대한 다양한 치수분들의 요청을 받기 위해
                    <span className="pink"> 계정당 5곡</span>
                    으로 요청이 제한돼요.
                    <br/>
                    요청한 곡이 수락되면 다른 곡을 요청하실 수 있어요.
                </p>
            </>
        );
    } else {
        return (
            <>
                <p className="title">
                    노래 신청 (Song Request)
                </p>
                <br />
                <p className="subTitle">
                    방장이 불러줬으면 하는 노래를 자유롭게 신청해주세요.
                    <br />
                    무분별한 신청을 막기 위해
                    <span className="pink"> 계정당 5곡</span>
                    으로 신청이 제한되며, 신청이 수락되면 다른 곡을 다시 신청할 수 있습니다.
                </p>
                <p className="text">현재 신청 가능한 곡 수: { 5 - userCount}곡</p>
                <Box
                    component="form"
                    sx={{
                        '& > :not(style)': { m: 1, width: '50%', maxWidth: '500px', minWidth: '150px' },
                        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#d87989', // 클릭된 상태의 아웃라인 컬러 변경
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#ff4e6b', // helperText의 색상 변경
                        }
                    }}
                    autoComplete="off"
                    onSubmit={handleSubmit}
                >
                    <TextField
                        required
                        id="songTitle"
                        label="노래 제목"
                        color="secondary"
                        variant="outlined"
                        onChange={(e) => setSongTitle(e.target.value)}
                        InputProps={{
                            style: {
                                color: '#ff4e6b', // 텍스트 색상 변경
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#ff4e6b', // 라벨 색상 변경
                            },
                        }}
                    />
                    <br />
                    <TextField
                        required
                        id="songArtist"
                        label="아티스트"
                        color="secondary"
                        variant="outlined"
                        onChange={(e) => setSongArtist(e.target.value)}
                        InputProps={{
                            style: {
                                color: '#ff4e6b', // 텍스트 색상 변경
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#ff4e6b', // 라벨 색상 변경
                            },
                        }}
                    />
                    <br />
                    <TextField
                        id="songLink"
                        label="링크 (선택사항)"
                        color="secondary"
                        variant="outlined"
                        onChange={(e) => setSongLink(e.target.value)}
                        helperText="노래를 들을 수 있는 링크가 있으면 더 쉽게 원하는 노래를 신청할 수 있어요."
                        InputProps={{
                            style: {
                                color: '#ff4e6b', // 텍스트 색상 변경
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                color: '#ff4e6b', // 라벨 색상 변경
                            },
                        }}
                    />
                    <br />
    
                    <div style={{ display: 'inline-block' }}>
                        <Button disabled={isDisabled} type="submit" style={{ width: 'auto' }}>
                            제출
                        </Button>
                    </div>
                </Box>
            </>
        );
    };
}

function NotLoggedInError() {
    return (
        <>
            <p className="title">먼저 로그인 해 주세요.</p>
            <br />
            <p className="subTitle">
                무분별한 요청을 막기 위해 인증된 사용자만
                이용할 수 있어요.<br />
                아래 버튼을 눌러 로그인 해 주세요!
            </p>
            <LoginBtn className={"loginShort"} />
        </>
    )
}

function Req(props) {
    return (
        <div className="fadeIn home">
            {Object.keys(props.user).length === 0 && (
                <Loading />
            )}
            {Object.keys(props.user).length !== 0 && props.user.user === 'none' && (
                <NotLoggedInError className={"login"} />
            )}
            {Object.keys(props.user).length !== 0 && props.user.user !== 'none' && (
                <div>
                    <BasicTextFields user = { props.user.user } />
                </div>
            )}
        </div>
    );
}

export default Req;