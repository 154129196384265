import { useState, useCallback } from 'react';
import MessageBox from './MessageBox';

const useMessageBox = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const showMessageBox = useCallback((title, message) => {
        setTitle(title);
        setMessage(message);
        setIsVisible(true);
    }, []);

    const hideMessageBox = useCallback(() => {
        setIsVisible(false);
    }, []);

    const MessageBoxComponent = isVisible ? (
        <MessageBox title={title} message={message} onClose={hideMessageBox} />
    ) : null;

    return { showMessageBox, MessageBoxComponent };
};

export default useMessageBox;