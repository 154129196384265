import React, { useState } from 'react';
import './css/MessageBox.css';

const MessageBox = ({ title, message, onClose }) => {
    return (
        <div className="message-box-overlay">
            <div className="message-box">
                <h2>{title}</h2>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default MessageBox;