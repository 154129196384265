import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { NavLink, Routes, Route, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import '../css/List.css';

const fetchCatList = () => {
    return fetch(`${window.location.origin}/api/cat`)
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/cat`)
                .then(response => response.json())
                .then(data => data)
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

function List() {
    const [catList, setCatList] = useState([]);
    const status = ['REQ', '알고 있음', '연습중', '가능', '완벽'];
    const cat = useParams()["*"];

    useEffect(() => {
        fetchCatList()
            .then((data) => {
                setCatList(data);
            })
    }, []);

    const navLinks = catList.map((item, index) => (
        <NavLink key={index} exact to={`/list/${item.toLowerCase()}`} onClick={() => setCategory(`${item.toLowerCase()}`)} activeClassName="active" tabIndex="-1">
            {item}
        </NavLink>
    ));

    const [songs, setSongs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [category, setCategory] = useState('');

    useEffect(() => {
        const fetchSongs = async (_cat) => {
            try {
                const response = await fetch(`${window.location.origin}/api/load`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ q: _cat.toUpperCase() })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setSongs(data);
                setIsLoading(false);
            } catch (error) {
                try {
                    const response = await fetch(`http://127.0.0.1:8000/api/load`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ q: _cat.toUpperCase() })
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    const data = await response.json();
                    setSongs(data);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                }
            }
        };

        if (cat) {
            fetchSongs(cat);
        } else {
            setIsLoading(false); // category가 정의되어 있지 않으면 로딩 상태 해제
        }
    }, []);

    useEffect(() => {
        const fetchSongs = async (_cat) => {
            try {
                const response = await fetch(`${window.location.origin}/api/load`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ q: _cat.toUpperCase() })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setSongs(data);
                setIsLoading(false);
            } catch (error) {
                try {
                    const response = await fetch(`http://127.0.0.1:8000/api/load`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ q: category.toUpperCase() })
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    const data = await response.json();
                    setSongs(data);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                }
            }
        };

        if (category) { // category가 정의되어 있는지 확인
            fetchSongs(category);
        } else {
            setIsLoading(false); // category가 정의되어 있지 않으면 로딩 상태 해제
        }
    }, [category]);

    return (
        <div className="fadeIn home">
            <p className="title">노래 리스트</p>
            <br />
            <p className="subTitle">방장이 부를 수 있는 / 알고 있는 / 연습중인 / 요청받은 노래 리스트입니다.</p>
            <p className="navigateInfo">
                ※
                휠 인식이 안 될 경우 <span className="inlineText">Shift + 마우스 흴</span>
                로 스크롤을 이동합니다.
            </p>
            <div className="songList">
                <div className="cat">
                    {navLinks}
                </div>
                <div className="list">
                    <div className="listGroup">
                        <span>구분</span>
                        <span>제목</span>
                        <span>가수</span>
                        <span>링크</span>
                    </div>
                    <div className="songs">
                        {isLoading ? (
                            <div className="loading">Loading...</div>
                        ) : (
                            <Routes>
                                <Route path="/" element={
                                    <div className="noneSelected">
                                        <div className="noneSelectedTxt">
                                            카테고리를 선택하시면<br />
                                            노래 리스트가 나타납니다.
                                        </div>
                                    </div>
                                } />
                                <Route path={`/${category !== '' && cat !== '' ? category : cat}`} element={
                                    <>
                                        {songs.map(song => (
                                            <div key={song.id}>
                                                <span>{status[song.status]}</span>
                                                <span title={song.title}>
                                                    {song.title}
                                                </span>
                                                <span title={song.artist}>
                                                    {song.artist}
                                                </span>
                                                {song.link ? (
                                                    <Button href={song.link} target="_blank">링크</Button>
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </div>
                                        ))}
                                    </>
                                } />
                            </Routes>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default List;