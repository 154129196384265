import React, { useState } from 'react';
import '../css/Main.css';

function Main() {
    return (
        <div className="fadeIn home">
            <p className="title">어라.. 여기로 오시면 안 되는데..</p>
            <br/>
            <p className="subTitle">옆에 있는 메뉴들 중 아무거나 눌러서 다시 돌아가실 수 있어요.</p>
        </div>
    );
}

export default Main;