import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './css/Side.css';
import $ from 'jquery';

const blockTrigger = () => {
    if ($('.narrowSide').hasClass('active') || $('.pageScroll').hasClass('active')) {
        $('.block').css({ display: 'none' });

        $('.narrowSide').removeClass('active');
        $('.narrowSide').removeAttr('style');

        $('.pageScroll').removeClass('active');
        $('.pageScroll').removeAttr('style');
    }
};

function Menu() {
    return (
        <>
            <div className="menu">
                <NavLink exact to="/" activeClassName="active" tabIndex="-1">Home</NavLink>
                <NavLink to="/list" activeClassName="active" tabIndex="-1">List</NavLink>
                <NavLink exact to="/req" activeClassName="active" tabIndex="-1">Request</NavLink>
            </div>  
        </>
    )
}

function MenuFullSize() {
    return (
        <div className="menu">
            <NavLink exact to="/" activeClassName="active" tabIndex="-1" onClick={blockTrigger}>Home</NavLink>
            <NavLink to="/list" activeClassName="active" tabIndex="-1" onClick={blockTrigger}>List</NavLink>
            <NavLink exact to="/req" activeClassName="active" tabIndex="-1" onClick={blockTrigger}>Request</NavLink>
        </div>
    )
}

function LoginBtn(props) {
    const [text, setText] = useState('');
    const loginRef = useRef(null);

    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACTP_APP_CLIENT_SECRET;
    const ssl = ( window.location.host === 'localhost:3000' ) ?
                'http' :
                'https'
    const rd = ( window.location.host === 'localhost:3000' ) ?
                'localhost%3A3000' :
                'aringsing.link'
    const url = `https://discord.com/oauth2/authorize?client_id=1239926731880796221&response_type=code&redirect_uri=${ssl}%3A%2F%2F${rd}%2Foauth&scope=identify+guilds`
    useEffect(() => {
        function handleResize() {
            const width = loginRef.current.clientWidth;
            if (width < 200) {
                setText('로그인');
            } else {
                setText('디스코드 로그인');
            }
        }

        handleResize(); // 초기 렌더링 시에도 호출하여 초기 텍스트를 설정합니다.
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={props.className} ref={loginRef} onClick={() => {window.location.href = url}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16"><path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"></path></svg>
            <span>
                {text}
            </span>
        </div>
    );
}

function Userinfo(props) {
    if ( props.user.user.id == 638993730107473961 || props.user.user.id == 406798443579834368) {
        return (
            <div className="info">
                <NavLink exact to="/acpt" className="admin" activeClassName="active" tabIndex="-1">
                    <i className="bi bi-key-fill"></i>
                </NavLink>
                <span>
                    {props.user.user.username}
                </span>
            </div>
        );
    } else {
        return (
            <div className="info_">
                <span>
                    {props.user.user.username}
                </span>
            </div>
        );
    }
}

function Loading() {
    return (
        <div className="loading">
            <span>
                인증 정보 처리중...
            </span>
        </div>
    );
}

function Side(props) {
    const trigger = () => {
        if (!($('.narrowSide').hasClass('active') || $('.pageScroll').hasClass('active'))) {
            $('.block').css({ display: 'block' });

            $('.narrowSide').addClass('active');
            $('.narrowSide').css({ left: '0' });

            $('.pageScroll').addClass('active');
            $('.pageScroll').css({ left: '0' });
        }
    };

    return (
        <>
            <i className="bi bi-list narrowMenuTrigger" onClick={trigger}></i>
            <div className="side">
                <Menu />
                {Object.keys(props.user).length === 0 && (
                    <Loading />
                )}
                {Object.keys(props.user).length !== 0 && props.user.user === 'none' && (
                    <LoginBtn className={"login"} />
                )}
                {Object.keys(props.user).length !== 0 && props.user.user !== 'none' && (
                    <Userinfo user = {props.user} />
                )}
            </div>
            <div className="narrowMenu">
                <div className="narrowSide">
                    <i className="bi bi-x closeMenuTrigger" onClick={blockTrigger}></i>
                    <MenuFullSize />
                    {Object.keys(props.user).length === 0 && (
                        <Loading />
                    )}
                    {Object.keys(props.user).length !== 0 && props.user.user === 'none' && (
                        <LoginBtn className={"login"} />
                    )}
                    {Object.keys(props.user).length !== 0 && props.user.user !== 'none' && (
                        <Userinfo user = {props.user} />
                    )}
                </div>
                <div className="block" onClick={blockTrigger}></div>
            </div>
            <div className="pageMenu">
                <div className="pageScroll">
                    <i className="bi bi-x closeMenuTrigger" onClick={blockTrigger}></i>
                    <MenuFullSize />
                    {Object.keys(props.user).length === 0 && (
                        <Loading />
                    )}
                    {Object.keys(props.user).length !== 0 && props.user.user === 'none' && (
                        <LoginBtn className={"login"} />
                    )}
                    {Object.keys(props.user).length !== 0 && props.user.user !== 'none' && (
                        <Userinfo user = {props.user} />
                    )}
                </div>
            </div>
        </>
    )
}

export default Side;
export { LoginBtn };
