import React, { useState } from 'react';
import '../css/Main.css';
import useMessageBox from '../useMessageBox';

function Main({ onClick }) {
    const [chzzkLink] = useState('https://chzzk.naver.com/live/869474ef6644ddc45c81a65b03975dce');
    const [cafeLink] = useState('https://cafe.naver.com/aringqueen');
    
    const { showMessageBox, MessageBoxComponent } = useMessageBox();

    const handleLinkClick = (link) => {
        onClick(link); // App.js의 handleClick 함수 호출
    };

    return (
        <div className="fadeIn home">
            <p className="title">환영합니다!</p>
            <br />
            <p className="subTitle">치지직 스트리머 <span className="pink">유아링</span>의 노래책입니다.</p>
            <p className="subTitle">신청 가능한 노래를 찾아보고, 듣고 싶은 노래를 자유롭게 신청해보세요.</p>
            
            <div title="치지직" className="socialBlock chzzk" onClick={() => window.open(chzzkLink)}>
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" fill="currentColor">
                    <path d="M242.1,189.1l-49.5-0.2l-49.5-0.3l54.5-75.2c30-41.4,54.5-75.5,54.5-75.8c0-0.3-19.8-0.5-44.1-0.5
                        c-24.2,0-43.9-0.4-43.7-0.8c0.2-0.4,5.9-8.7,12.8-18.4l12.5-17.7h33.3l33-0.1v0H100.1l-3.4,4.2C93.8,7.9,33.4,91,28.6,98
                        c-0.9,1.3-1.7,2.8-1.7,3.2c0,0.4,19.7,0.9,43.9,1l43.8,0.3l-55.4,76.5c-36,49.6-56.1,76.6-57.4,76.8c-1.9,0.3-1.9-1.4-1.9-121.9
                        v121.9h242.2v-33.3V189.1z" />
                </svg>
            </div>
            <div title="팬카페" className="socialBlock twitch" onClick={() => window.open(cafeLink)}>
                <svg version="1.1" x="0px" y="0px" viewBox="0 0 256 256" fill="currentColor">
                    <path d="M0,156.2c0,55.3,44.9,100.2,100.3,100.2c43.2,0,80.1-27.3,94.2-65.6h15.1c25.7,0,46.5-20.8,46.5-46.4
                        c0-25.6-20.8-46.4-46.5-46.4h-9H0V156.2L0,156.2z M199.6,169.6c0.6-4.4,0.9-8.9,0.9-13.4v-37.3h9c14,0,25.4,11.4,25.4,25.4
                        c0,14-11.4,25.4-25.4,25.4H199.6z"/>
                    <path d="M41.9,66.8h66.8h8.6c36.9,0,66.8-29.9,66.8-66.8h-66.8h-8.6C71.8,0,41.9,29.9,41.9,66.8z"/>
                </svg>
            </div>
            {MessageBoxComponent}
        </div>
    );
}

export default Main;
