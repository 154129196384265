import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import './css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";

import Main from './routes/Main';
import List from './routes/List';
import Req from './routes/Req';
import Acpt from './routes/Acpt';
import Oauth from './routes/oauth';
import NotFound from './routes/NotFound';

import useMessageBox from './useMessageBox';

import Side from './Side';

function App() {
    const [ cookies, setCookie, removeCookie ] = useCookies();
    const [ user, setUser ] = useState({});

    const { showMessageBox, MessageBoxComponent } = useMessageBox();

    const getUser = async(aT) => {
        const userInfoResponse = await fetch('https://discord.com/api/users/@me', {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${aT}`
            }
        });
        
        const userInfoResponseData = await userInfoResponse.json();
        setUser({ user: userInfoResponseData });
    }
    
    const getToken = async(rT) => {
        if (cookies['rT']) {
            const clientId = process.env.REACT_APP_CLIENT_ID;
            const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
            
            const data = {
                client_id: clientId,
                client_secret: clientSecret,
                grant_type: 'refresh_token',
                refresh_token: rT,
                scope: 'identify guilds'
            }
            const response = await fetch('https://discord.com/api/oauth2/token', {
                method: 'POST',
                body: new URLSearchParams(data),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
            
            const responseData = await response.json();
            const expirationDate = new Date(Date.now() + responseData.expires_in * 1000);

            setCookie('rT', responseData.refresh_token, { path: '/', expires: expirationDate });
            setCookie('aT', responseData.access_token, { path: '/' });

            getUser(cookies['aT']);
        } else {
            setUser({ user: 'none' });
        }
    }

    const handleClick = (title, message) => {
        showMessageBox(title, message);
    };

    useEffect(() => {        
        cookies['aT'] ? getUser(cookies['aT']) : setUser({ user: 'none' });
    }, []);

    return (
        <Router>
            <div className="App nanumsquare">
                {MessageBoxComponent}
                <Routes>
                    <Route path="/oauth" />
                    <Route path="*" element={<Side user = {user} />} />
                </Routes>
                <Routes>
                    <Route path="/" element={
                        <div className="main">
                            <Main user = {user} onClick={handleClick} />
                        </div>} />
                    <Route path="/list/*" element={
                        <div className="main">
                            <List user = {user} onClick={handleClick}  />
                        </div>} />
                    <Route path="/req" element={
                        <div className="main">
                            <Req user = {user} onClick={handleClick} />
                        </div>} />
                    <Route path="/acpt" element={
                        <div className="main">
                            <Acpt user = {user} onClick={handleClick} />
                        </div>} />
                    <Route path="/oauth" element={<Oauth user = {user}/>} />
                    <Route path="*" element={
                        <div className="main">
                            <NotFound user = {user} onClick={handleClick}/>
                        </div>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;