import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import '../css/List.css';

const fetchCatList = () => {
    return fetch(`${window.location.origin}/api/cat`)
        .then(response => response.json())
        .then(data => data)
        .catch(error => {
            return fetch(`http://127.0.0.1:8000/api/cat`)
                .then(response => response.json())
                .then(data => data)
                .catch(error => {
                    console.error('Error:', error);
                    throw error;
                });
        });
};

function AcptForm(props) {
    const [userId, setUserId] = useState(null);
    const [songs, setSongs] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const radioGroupRefs = useRef({});

    const getSelectedValue = async (songId) => {
        const radios = radioGroupRefs.current[songId].querySelectorAll(`input[name="${songId}"]`);
        let selectedValue = null;
        for (const radio of radios) {
            if (radio.checked) {
                selectedValue = radio.value;
                const msg = `요청을 수락힙니다.

※ 이 작업은 취소할 수 없습니다.
※ 곡의 상태는 '알고 있음' 으로 자동 결정됩니다.

계속할까요?`
                const userConfirmed = window.confirm(msg);
                if (userConfirmed) {
                    try {
                        const response = await fetch(`${window.location.origin}/api/acpt`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                id: songId,
                                cat: selectedValue
                            })
                        });
                
                        if (response.ok) {
                            alert("요청이 수락되었어요.");
                            window.location.reload();
                        } else {
                            throw new Error('Failed to submit form data');
                        }
                    } catch (error) {
                        console.log('a')
                        try {
                            const response = await fetch(`http://127.0.0.1:8000/api/acpt`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    id: songId,
                                    cat: selectedValue
                                })
                            });
        
                            if (response.ok) {
                                alert("요청이 수락되었어요.");
                                window.location.reload();
                            } else {
                                throw new Error('Failed to submit form data');
                            }
                        } catch (error) {
                            console.error('Error submitting form:', error);
                        }                
                    }
                }
                break;
            }
        }
        console.log(selectedValue);
    };

    useEffect(() => {
        if (props.user.user) {
            setUserId(props.user.user.id);
        }
    }, [props.user]);

    useEffect(() => {
        const fetchSongs = async (_cat) => {
            try {
                const response = await fetch(`${window.location.origin}/api/load`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ q: _cat.toUpperCase() })
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                const data = await response.json();
                setSongs(data);
                setIsLoading(false);
            } catch (error) {
                try {
                    const response = await fetch(`http://127.0.0.1:8000/api/load`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ q: _cat.toUpperCase() })
                    });

                    if (!response.ok) {
                        throw new Error('Failed to fetch data');
                    }

                    const data = await response.json();
                    setSongs(data);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    setIsLoading(false);
                }
            }
        };

        fetchSongs("_REQ");
    }, []);

    if (userId) {
        if (userId == 638993730107473961 || userId == 406798443579834368) {
            return (
                <>
                    <p className="subTitle">
                        요청 받은 노래를 수락합니다.
                        <br />
                        카테고리를 선택 한 후 수락 버튼을 클릭해주세요.
                    </p>
                    <div className="songList_acpt">
                        <div className="list">
                            <div className="listGroup_">
                                <span>제목</span>
                                <span>가수</span>
                                <span>링크</span>
                            </div>
                            <div className="songs_">
                                {isLoading ? (
                                    <div className="loading">Loading...</div>
                                ) : (
                                    <>
                                        {songs.map(song => (
                                            <div className="songDiv" key={song.id} ref={el => radioGroupRefs.current[song.id] = el}>
                                                <div className="infoDiv">
                                                    <span title={song.title}>
                                                        {song.title}
                                                    </span>
                                                    <span title={song.artist}>
                                                        {song.artist}
                                                    </span>
                                                    {song.link ? (
                                                        <Button href={song.link} target="_blank">링크</Button>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                                <div className="acpt">
                                                    <div>
                                                        <input type="radio" name={song.id} id={`cat1-${song.id}`} value="K-POP" />
                                                        <label htmlFor={`cat1-${song.id}`}>K-POP</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" name={song.id} id={`cat2-${song.id}`} value="POP" />
                                                        <label htmlFor={`cat2-${song.id}`}>POP</label>
                                                    </div>
                                                    <div>
                                                        <input type="radio" name={song.id} id={`cat3-${song.id}`} value="RAP" />
                                                        <label htmlFor={`cat3-${song.id}`}>RAP</label>
                                                    </div>
                                                    <Button onClick={() => getSelectedValue(song.id)}>수락</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <p className="subTitle">
                    관리자가 아닙니다.
                </p>
            );
        }
    } else {
        return (
            <p className="subTitle">
                잠시 기다려 주세요...
            </p>
        );
    }
}

function Acpt(props) {
    return (
        <div className="home">
            <AcptForm user={props.user} />
        </div>
    );
}

export default Acpt;
